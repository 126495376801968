<template>
    <div id="auth-view">
        <div class="uk-section uk-section-large uk-flex uk-flex-column uk-flex-center uk-flex-middle">
            <img  src="@/assets/theme/img/logo.svg" alt="Logo" width="200px" height="200px">
            <form class="form login-form uk-flex uk-flex-column">
                <input v-model="email" type="email" name="email-input" class="form-control uk-form-width-large uk-margin-bottom" placeholder="Email">
                <input v-model="password" type="password" name="password-input" class="form-control uk-form-width-large uk-margin-bottom" placeholder="Password">
                <button v-if="!loading" v-on:click="login" type="button" class="uk-button-large uk-width-1-1 btn-transparent">Login</button>
                <div class="uk-text-center" v-if="loading" uk-spinner="ratio: 3"></div>
            </form>
        </div>
    </div>
</template>

<script>
import { SERVER_URL } from "@/.env";
import Axios from 'axios';
import Cookies from "../../node_modules/js-cookie/src/js.cookie";
import { Notifier } from "@/services/Notifier";
import { Hasher } from "@/services/Hasher";

const uniqid = require('uniqid');

export default {
    name: 'Auth',
    data: function() {
        return {
            email: '',
            password: '',
            uniqueId: '',
            loading: false
        }
    },
    methods: {
        /**
         * Makes a login request to the Laravel server
         */
        login() {
            this.loading = true;

            // Check if this device is registered
            this.uniqueId = Cookies.get('uniqueId');
            if (this.uniqueId == undefined) {
                // Generate uniqueid and save it in the cookies
                this.uniqueId = uniqid('ORG-');
                Cookies.set('uniqueId', this.uniqueId, { expires: 365 * 50});

                // Test if cookie was set
                if (!Cookies.get('uniqueId')) {
                    this.loading = false;
                    Notifier.notify('Nu am putut inregistra acest dispozitiv, mai incercati!', 'warning');
                    return;
                }
            }

            Axios.post(
                SERVER_URL + '/api/login',
                {
                    email: this.email,
                    password: Hasher.hash(this.password),
                    uniqueId: this.uniqueId
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                // Redirect to the home page
                Cookies.set('X-XSRF-TOKEN', response.data.token);
                console.log('Authentication successful');
                this.loading = false;
                localStorage.setItem('userId', response.data.user_id);
                this.$router.push('Home');
                Notifier.notify('Autentificare cu succes', 'success');
            })
            .catch(() => {
                Notifier.notify('Autentificare esuata', 'danger');
                this.loading = false;
            })
        }
    }
}
</script>

<style lang="less" scoped>
    @import "../assets/helpers.less";

    #auth-view {
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/theme/banner/office-building.webp");
        background-size: cover;
        background-blend-mode: darken;

        form {
            input {
                font-family: 'Lato';
                font-weight: 700;
                .fluid-font(1, 15, 320, 1920);
            }

            button {
                font-family: 'Lato';
                font-weight: 700;
                .fluid-font(1.2, 20, 320, 1920);
                color: white;
            }

            .uk-icon {
                color: white;
            }
        }
    }
</style>