import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from "../views/Auth.vue";
import Cookies from "../../node_modules/js-cookie/src/js.cookie";
import Axios from 'axios';

import { SERVER_URL } from '../.env.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "landing" */ '../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'Organizations',
        component: () => import(/* webpackChunkName: "landing" */ '../components/Organizations.vue')
      },
      {
        path: 'organization/details',
        name: 'OrganizationDetails',
        component: () => import (/* webpackChunkName: "organizations" */ '../components/Reusables/OrganizationDetails.vue')
      }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
});

async function checkAuth(userId, token) {
  // Make a request to the server to see if credentials are valid
  let isAuthenticated = await Axios.post(
    SERVER_URL + '/org/api/checkAuth',
    {
      initiator_id: userId
    },
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
  .then(() => {
    return true;
  })
  .catch(() => {
    return false;
  });

  return isAuthenticated;
}

/**
 * Register authentication guard to prevent unauthenticated access to the app
 */
router.beforeEach(async (to, from, next) => {
  // Check the cookies
  const token = Cookies.get('X-XSRF-TOKEN');
  const userId = localStorage.getItem('userId');

  let isAuthenticated = await checkAuth(userId, token);

  // Trying to go back to login page without logging out
  if (to.name == 'Auth' && isAuthenticated) {
    // Stay on same page. Do not honor request.
    next({name: from.name});
  }
  // Trying to access a page other than Auth without being authenticated
  else if (to.name != 'Auth' && !isAuthenticated){
    // Redirect to Auth
    next({name: 'Auth'});
  }
  else {
    next();
  }
});

export default router
