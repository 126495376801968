/**
 * This module will serve as the state store for the section where we asign contents from diferent 
 * products to diferent users inside an organization.
 */

import Axios from 'axios';
import Cookies from 'js-cookie/src/js.cookie';
import Cacher from '@/services/Cacher';
import { SERVER_URL } from '@/.env';

const organizationModule = {
    state: {
        org: Object,
        users: [],
        filteredUsers: [],
        selectedUsers: []
    },

    getters: {
        users: state => {
            return state.users;
        },

        selectedUsers: state => {
            return state.selectedUsers;
        }
    },

    mutations: {
        setOrganization(state, org) {
            state.org = org;
        },

        setUsers(state, users) {
            state.users = users;
        },

        addUser(state, user) {
            state.users.push(user);
        },

        removeFromUsers(state, index) {
            state.users.splice(index, 1);
        },

        removeFromFilteredUsers(state, index) {
            state.filteredUsers.splice(index, 1);
        },

        selectUser(state, user) {
            state.selectedUsers.push(user);
        },

        deselectUser(state, index) {
            state.selectedUsers.splice(index, 1);
        },

        resetUsers(state) {
            state.selectedUsers.forEach(user => {
                state.users.push(user);
            });

            state.selectedUsers = [];
        },

        setFilteredUsers(state, filteredUsers) {
            state.filteredUsers = filteredUsers;
        },

        resetOrg(state) {
            state.org = undefined;
        },

        resetAllUsers(state) {
            state.users = [];
        },

        resetFilteredUsers(state) {
            state.filteredUsers = [];
        },

        resetSelectedUsers(state) {
            state.selectedUsers = [];
        }
    },

    actions: {
        /**
         * This method loads the users from the cloud.
         * 
         * @param {Commit} commit The commit method for the local context
         * @param {State} state The local module state
         */
        async loadUsers({ commit, state }) {
            let token = Cookies.get('X-XSRF-TOKEN');
            await Axios.put(
                SERVER_URL + '/org/api/get_users',
                {
                    initiator_id: Cacher.getFromCache('userId'),
                    org_id: state.org.idOrg
                },
                {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                }
            )
                .then(response => {
                    console.log(response.data.msg);
                    commit('setUsers', response.data.users);
                })
                .catch(reason => {
                    console.error(reason.response.data.msg);
                });
        },

        selectUser({ commit, state }, index) {
            let user = state.filteredUsers[index];
            commit('removeFromFilteredUsers', index);
            commit('removeFromUsers', index);
            commit('selectUser', user);
        },

        deselectUser({ commit, state }, index) {
            let user = state.selectedUsers[index];
            commit('deselectUser', index);
            commit('addUser', user);
        },

        /**
         * This action will filter the users by email. Only first 7 results will be returned.
         * 
         * @param {*} commit Vuex module commit method
         * @param {*} state Vuex module local state
         * @param {String} searchedUser The email of the searched user
         */
        filterUsers({ commit, state }, searchedUser) {
            let filtered = state.users.filter(element => {
                return element.email.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1;
            })
                .slice(0, 7);

            commit('setFilteredUsers', filtered);
        }
    },

    modules: {
        /**
         * The productsModule will handle the management of the products state
         */
        productModule: {
            namespaced: true,

            state: {
                products: []
            },

            mutations: {
                setProducts(state, products) {
                    state.products = products;
                },

                resetAll(state) {
                    state.products = [];
                }
            },

            actions: {
                async loadProducts({ commit, rootState }) {
                    await Axios.put(
                        SERVER_URL + '/org/api/get_products',
                        {
                            initiator_id: Cacher.getFromCache('userId'),
                            org_id: rootState.organizationModule.org.idOrg
                        },
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + Cookies.get('X-XSRF-TOKEN')
                            }
                        }
                    )
                        .then(response => {
                            console.log(response.data.msg);
                            commit('setProducts', response.data.products);
                        })
                        .catch(reason => {
                            console.error(reason.response.data.msg);
                        });
                },

                resetAll({commit}) {
                    commit('resetAll');
                } 
            }
        },

        contentModule: {
            namespaced: true,

            state: {
                content: [],
                selectedContent: [],
                filteredContent: []
            },

            getters: {
                content: state => {
                    return state.content;
                },

                selectedContent: state => {
                    return state.selectedContent;
                },

                filteredContent: state => {
                    return state.filteredContent;
                }
            },

            mutations: {
                setContent(state, content) {
                    state.content = content;
                },

                selectContent(state, index) {
                    state.selectedContent.push(state.filteredContent[index]);
                },

                deselectContent(state, contentId) {
                    state.selectedContent.splice(
                        state.selectedContent.findIndex(element => element.content_id == contentId),
                        1
                    );
                },

                setFilteredContent(state, filtered) {
                    state.filteredContent = filtered;
                },

                resetAll(state) {
                    state.content = [];
                    state.selectedContent = [];
                    state.filteredContent = [];
                }
            },

            actions: {
                async loadContent({ commit, rootState }, productId) {
                    await Axios.put(
                        SERVER_URL + '/org/api/get_content',
                        {
                            initiator_id: Cacher.getFromCache('userId'),
                            org_id: rootState.organizationModule.org.idOrg,
                            product_id: productId
                        },
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + Cookies.get('X-XSRF-TOKEN')
                            }
                        }
                    )
                        .then(response => {
                            console.log(response.data.msg);
                            commit('setContent', response.data.content);
                        })
                        .catch(reason => {
                            console.error(reason.response.data.msg);
                        });
                },

                /**
                 * This action will filter the contents and will replace the filteredContents[] array with the
                 * result of this operation.
                 * 
                 * @param {*} commit Vuex module commit method
                 * @param {*} state Vuex module local state
                 * @param {String} searchedContent The name of the content that is searched for
                 */
                filterContent({ commit, state }, searchedContent) {
                    let filtered = state.content.filter(element => {
                        return element.ContentName.toLowerCase().indexOf(searchedContent.toLowerCase()) > -1;
                    });

                    commit('setFilteredContent', filtered);
                },

                resetAll({commit}) {
                    commit('resetAll');
                }
            }
        }
    }
}

export { organizationModule };