const searchModule = {
    state: {
        searchedTerm: ''
    },

    mutations: {
        changeTerm (state, term) {
            state.searchedTerm = term;
        },

        resetSearchTerm(state) {
            state.searchedTerm = '';
        }
    }
}

export { searchModule }