import Vue from 'vue'
import Vuex from 'vuex'

import Axios from 'axios';
import Cookies from 'js-cookie/src/js.cookie';
import { SERVER_URL } from '@/.env';
import Cacher from '@/services/Cacher';

// Importing modules
import { organizationModule } from '@/store/modules/OrganizationModule';
import { searchModule } from '@/store/modules/SearchModule';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orgs: [],
  },
  mutations: {
    setOrganizations(state, orgs) {
      state.orgs = orgs;
    },

    resetOrgs(state) {
      state.orgs = [];
    }
  },
  actions: {
    async loadOrganizations({ commit }) {
      await Axios.put(
        SERVER_URL + '/org/api/get_owned_orgs',
        {
          initiator_id: Cacher.getFromCache('userId'),
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get('X-XSRF-TOKEN')
          }
        }
      )
      .then(reason => {
        console.log(reason.data.msg);
        commit('setOrganizations', reason.data.orgs);
      })
      .catch(reason => {
        console.log(reason.response.data.msg);
      });
    },

    resetAll({commit, dispatch}) {
      commit('resetOrgs');
      commit('resetOrg');
      commit('resetAllUsers');
      commit('resetFilteredUsers');
      commit('resetSelectedUsers');
      commit('resetSearchTerm');

      dispatch('productModule/resetAll', null, {root: true});
      dispatch('contentModule/resetAll', null, {root: true});
    }
  },
  modules: {
    organizationModule,
    searchModule
  }
})
