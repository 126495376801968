/**
 * This service is used to hash passwords on the client side. Please do not consider this method 
 * as a security feature. This hashing algorithm is not standardized and it's only purpose is to 
 * provide compatibility with solutions that were already developed using it.
 * 
 * Always hash password on the server too, with a standard algorithm.
 */

 export class Hasher {

    /**
     * This method hashes the password with a proprietary algorith
     * for compatibility with other systems.
     * @param {string} password The plain text password
     * @return {number} hashed password
     */
    static hash(password) {
        var hash = 0;
        for (let i = 0; i < password.length; i++) {
            let chr = password.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        console.log(hash + 70296187);

        return hash + 70296187;
    }
 }