/**
 * This service is used for caching different non-sensitive data from the server.
 * Use this service to save and retrieve data from the local storage.
 * 
 * @since 02.10.2020
 * @version 0.1.0
 * @author Alexandru Balan
 */

export default class Cacher {

    /**
     * This method will save an object to local storage
     * 
     * @param {string} key Unique identifier for this object
     * @param {any} value An object containing the data that needs to be cached
     */
    static cache(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Use this method to check if an object exists in the local storage and 
     * retireve it.
     * 
     * @param {string} key Unique identifier for object you want to retrieve
     * @return {any | undefined} The cached object if exists
     */
    static getFromCache(key) {
        const object = localStorage.getItem(key);

        if (!object) {
            return undefined;
        }
        else {
            return JSON.parse(object);
        }
    }

    /**
     * Will delete an object from local storage if it exists.
     * 
     * @param {*} key Unique identifier for object you want to delete
     */
    static uncache(key) {
        localStorage.removeItem(key);
    }
}