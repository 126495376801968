import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'uikit';
import {library} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faHome, faSignOutAlt, faTimesCircle, faSearch } from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faSignOutAlt, faTimesCircle, faSearch);

Vue.component('fa-icon', FontAwesomeIcon);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
