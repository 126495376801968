import UIkit from 'uikit';

export let Notifier = {
    /**
     * 
     * @param {string} message The message of the notification
     * @param {string} status One of: primary, success, danger, wawrning
     */
    notify: function(message, status) {
        UIkit.notification({
            message: message,
            status: status
        });
    }
}